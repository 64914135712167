import {Fragment} from "react";

const Contact = () => {
    
    return(
        <Fragment>
            <h2 className="title-product">Où me retrouver ?</h2>
            <div className="contact-page">
                <div>
                	<address>
                	    <h3>Mes coordonnées : </h3>
                		<p><span className="days">Mail : </span><a href="mailto:oreapatisserie@gmail.com" rel="noreferrer" target="_blank">oreapatisserie@gmail.com</a></p>
                		<p><span className="days">Téléphone : </span><a href="tel:+336675360419" rel="noreferrer" target="_blank">06 75 36 04 19</a></p>
                		<p><span className="days">Adresse : </span>507 La Verdinière 44810 HERIC</p>
                    </address>
                </div>
                <div className="location-market">
                    <ul>
                        <h3>Sur les marchés : </h3>
                        <li><span className="days">Mercredi matin : </span> Châteaubriant</li>
                        <li><span className="days">Jeudi matin : </span> Treillières</li>
                        <li><span className="days">Vendredi soir : </span> Sucé-sur-Erdre</li>
                    </ul>
                </div>
                <div>
                    <h3>Réseaux sociaux</h3>
                    <div className="networks">
                        <div>
                            <a href="https://www.facebook.com/profile.php?id=100082526761298" rel="noreferrer" target="_blank"><i className="fa-brands fa-facebook-f"></i></a>
                            <a href="https://www.linkedin.com/in/oréa-pâtisserie-4b436a276/" target="_blank" rel="noreferrer"><i className="fa-brands fa-linkedin-in"></i></a>
                            <a href="https://www.instagram.com/orea.patisserie/?hl=fr" target="_blank" rel="noreferrer"><i className="fa-brands fa-instagram"></i></a>
                        </div>
                    </div>
                </div>
                <address className="info-compl">
                    <p>Pour toute information complémentaire ou demande particulière, n'hésitez pas à me contacter à l'adresse suivante : 
                        <span  className="days">
                            <a href="mailto:oreapatisserie@gmail.com" rel="noreferrer" target="_blank"> oreapatisserie@gmail.com</a>
                        </span>
                    </p>
                </address>
            </div>
        </Fragment>
    );
};

export default Contact;