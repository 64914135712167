import business from "../images/Réducs.jpg";
import wedding from "../images/Number cake.jpg";
import grandsGateaux from "../images/Grands_gâteaux.jpg";
import eclair from "../images/eclair.jpg";
import numberCake from "../images/Number_Cakes.png";
import { NavLink} from "react-router-dom";

const Services = () => {

    return(
        <div className="services">
            <h2 className="title-product">Pour les professionnels</h2>
            <main className="container">
                <div className="service-pro">
                    <div>
                        <img src={business} alt="coffrets cadeaux pâtisseries"/>
                        <div>
                            <p>
                            Coffrets fin d’année (cookies, pot de caramel, macarons, bonbons chocolat…)
                            </p>
                            
                            <p>
                            Cocktail (réductions sucrées, macarons)
                            </p>
                            <p>
                            Evènements d’entreprise (anniversaire, départ en retraite…)
                            </p>
                            <p>
                            Prestations sur mesure et personnalisées
                            </p>
                            
                            <p>
                            <NavLink to="/contact">Me contacter</NavLink>
                            </p>
                        </div>
                    </div>
                    <div className="clear"></div>
                </div>
                <h2 className="title-product">Pour les particuliers</h2>
                <div className="service-particulier">
                    <div>
                        <img src={wedding} alt="gâteau de mariage"/>
                        <div>
                            <p>
                            Anniversaire
                            </p>
                            <p>
                            Mariage
                            </p>
                            <p>
                            Number cake
                            </p>
                            <p>
                            Pièces montées
                            </p>
                            <p>
                            Prestations sur mesure et personnalisées
                            </p>
                            <p>
                                <NavLink to="/contact">Me contacter</NavLink>
                            </p>
                        </div>
                    </div>
                    <div className="clear"></div>
                </div>
                <div id="info-services">
                    <p>Les différentes sortes de grands gâteaux sont :</p>
                    <ul>
                        <li>Paris-Brest (uniquement 4, 6 et 8 personnes)</li>
                        <li>Tarte citron noisette</li>
                        <li>Entremets fruits du moment : Tropiques (banane mangue passion)</li>
                        <li>Entremets chocolat du moment : Choco’Noisette (praliné noisette chocolat)</li>
                        <li>Tarte fraises chantilly mascarpone (en saison)</li>
                        <li>Pavlova fruits rouges (en saison)</li>
                        <li>Fraisier (en saison)</li>
                    </ul>
                    <p>Les éclairs fleur de sel sont disponibles uniquement en individuels. Au-delà de 8 personnes, les Paris-Brest sont disponibles uniquement en individuels également.</p>
                    <p>Les grands gâteaux sont disponibles à partir de 4 personnes. Il n’y a pas de nombre maximum de parts.</p>
                    <img src={grandsGateaux} alt="grandsGateaux"/>
                    <p>Je réalise également des Number Cakes en forme de chiffres et/ou lettres.</p>
                    <p>Les différentes saveurs sont :</p>
                    <ul>
                        <li>Vanille fraises fraîches (en saison)</li>
                        <li>Vanille spéculoos</li>
                        <li>Citron</li>
                        <li>Chocolat caramel</li>
                        <li>Chocolat praliné noisette</li>
                        <li>Chocolat caramel cacahuètes</li>
                    </ul>
                    <p>Il est possible de panacher les différents parfums et le nombre minimum de parts est de 8 personnes. Les gabarits sont découpés en fonction du nombre de parts que vous souhaitez.</p>
                    <img src={numberCake} alt="numberCake"/>
                    <p>Je peux également vous proposer des « éclairs classiques » : caramel beurre salé, citron, framboise ou chocolat. Il est possible de panacher les différents parfums et le nombre minimum de parts est de 8 éclairs/saveur.</p>
                    <img className="eclair" src={eclair} alt="eclair"/>
                    <p>Pour toute demande, veuillez me contacter sur ce site ou envoyer un mail à l’adresse suivante :
                        <a href="mailto:oreapatisserie@gmail.com" rel="noreferrer" target="_blank"> oreapatisserie@gmail.com</a>
                    </p>
                </div>
            </main>
        </div>
    );
};

export default Services;