import eggs from "../images/oeufs-vi.png";
import minoterie from "../images/minoterie.png";
import verge from "../images/verge1.jpg";
import {Fragment} from"react"

const Origine = () => {

    return(
        <Fragment>
            <div className="origin-matter">
                <div>
                    <div>
                        <h3>Farine :</h3>
                        <p>Minoterie Bourseau</p>
                        <p>NOZAY (44170)</p>
                    </div>
                    <a href="https://www.minoterie-bourseau.com/" rel="noreferrer" target="_blank"><img src={minoterie} alt="minoterie bourseau"/></a>
                </div>
                <div className="minier">
                    <div>
                        <h3>Oeufs :</h3>
                        <p>Ferme de la Forêt</p>
                        <p>Vieillevigne (44116)</p>
                    </div>
                    <a href="https://www.fermedelaforet.fr" rel="noreferrer" target="_blank"><img src={eggs} alt="L'oeuf MINIER"/></a>
                </div>
                <div>
                    <div>
                        <h3>Fruits :</h3>
                        <p>Vergers du Bois Macé</p>
                        <p>LE CELLIER (44850)</p>
                    </div>
                    <a href="https://www.vergers-boismace.com/" rel="noreferrer" target="_blank"><img src={verge} alt="vergers du bois maçé"/></a>
                </div>
            </div>
        </Fragment>
    );
};

export default Origine;