
const MentionsLegales = () => {

    return(
            <main className="container">
                <h2>Mentions légales</h2>
                <p>En vigueur au 09/05/2023</p>
                <p>Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique, dite L.C.E.N., il est porté à la connaissance des utilisateurs et visiteurs, ci-après l'"Utilisateur", du site oreapatisserie.fr , ci-après le "Site", les présentes mentions légales.</p>
                <p>La connexion et la navigation sur le Site par l’Utilisateur implique acceptation intégrale et sans réserve des présentes mentions légales.</p>
                <p>Ces dernières sont accessibles sur le Site à la rubrique « <strong>Mentions légales</strong> ».</p>
                
                <h2>ARTICLE 1 - L'EDITEUR</h2>
                
                <p>L'édition du Site est assurée par MADAME OPHELIE ROBIN entreprise individuelle au capital de 0 euros, immatriculée au Registre du Commerce et des Sociétés de Nantes sous le numéro 952363786 dont le siège social est situé au 507 La verdinière 448810 HERIC</p>
                <p>Numéro de téléphone 0675360419, </p>
                <p>Adresse e-mail : oreapatisserie@gmail.com</p>
                <p>N° de TVA intracommunautaire : FR20952363786</p>
                <p>Le Directeur de la publication est ROBIN Ophélie </p>
                <p>ci-après l'"Editeur".</p>
                
                <h2>ARTICLE 2 - L'HEBERGEUR</h2>
                
                <p>L'hébergeur du Site est la société HOSTINGER, dont le siège social est situé au HOSTINGER INTERNATIONAL LTD, 61 Lordou Vironos Street, 6023 Larnaca, Chypre , avec l'adresse mail suivante : sales@hostinger.com</p>
                
                <h2>ARTICLE 3 - ACCES AU SITE</h2>
                
                <p>Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption programmée ou non et pouvant découlant d’une nécessité de maintenance.</p>
                <p>En cas de modification, interruption ou suspension du Site, l'Editeur ne saurait être tenu responsable.</p>
                <h2>ARTICLE 4 - COLLECTE DES DONNEES</h2>
                <p>Le Site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés. </p>
                <p>En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur exerce ce droit :</p>
                <ul>
                    <li>par mail à l'adresse email oreapatisserie@gmail.com</li>
                    <li>via un formulaire de contact </li>
                </ul>
                <p>Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou partie du Site, sans autorisation de l’Editeur est prohibée et pourra entraînée des actions et poursuites judiciaires telles que notamment prévues par le Code de la propriété intellectuelle et le Code civil.</p>
            </main>
    );
};

export default MentionsLegales;